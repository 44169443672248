import React, {useState} from "react";
import {Link, NavLink} from "react-router-dom";
import Join from "./Join";
import {Player} from "@lottiefiles/react-lottie-player";
import burger from "../assets/lottie/burger.json";
import Logo from "./Logo";

const Navbar = () => {
	const links = [
		{title: "Home", url: ""},
		{title: "Services", url: "services"},
		{title: "FAQ", url: "faq"},
		{title: "About", url: "about"},
	];
	const [open, setOpen] = useState(false);
	const burgerPlayer = React.createRef<Player>();

	const toggleDrawer = (e: React.MouseEvent) => {
		setOpen(!open);
		burgerPlayer.current!.setPlayerDirection(open ? -1 : 1);
		burgerPlayer.current!.play();
	};
	return (
		<>
			<nav className='flex relative w-screen items-center xs:hidden bg-[#fbf5f1] z-50 h-[10vh] justify-between px-[5%] pt-[1.5%]'>
				<div className=' w-[25%] bg-[#fbf5f1]'>
					<Link
						to='/'
						className='inline-flex z-50 items-center gap-[15px] no-underline'
					>
						<Logo />
					</Link>
				</div>
				<div className='z-50 inline-flex items-start gap-[17px]'>
					{links.map((link) => {
						return (
							<NavLink
								to={`/${link.url}`}
								className={({isActive}) =>
									(isActive
										? "pointer-events-none cursor-default text-[#f9772c] [-webkit-box-reflect:below_-30px_linear-gradient(transparent,_rgba(56,_76,_82,_0.25))] "
										: "text-[#f9972c] hover:text-[#f9772c] hover:[-webkit-box-reflect:below_-30px_linear-gradient(transparent,_rgba(114,_222,_112,_0.3))] ") +
									"relative flex items-center justify-center gap-[10px] p-[10px] no-underline transition-all"
								}
							>
								<div className='font-navlink-desktop relative ml-[-2.60px] mr-[-2.40px] mt-[-1.10px] w-fit whitespace-nowrap text-[length:var(--navlink-desktop-font-size)] font-[number:var(--navlink-desktop-font-weight)] leading-[var(--navlink-desktop-line-height)] tracking-[var(--navlink-desktop-letter-spacing)] [-webkit-text-stroke:0.1px_#ffffff] [font-style:var(--navlink-desktop-font-style)]'>
									{link.title}
								</div>
							</NavLink>
						);
					})}
				</div>
				<div className='z-50 w-[25%] justify-end flex'>
					<Join />
				</div>
			</nav>
			<nav className='xs:flex hidden relative w-screen items-center bg-[#fbf5f1] z-50 h-[10vh] justify-between'>
				<Link
					to='/'
					className='inline-flex z-50 w-[50%] bg-[#fbf5f1] items-center h-full justify-start gap-[15px] pl-[5%] pt-[1.5%] no-underline'
				>
					<Logo />
				</Link>
				<div
					className='z-50 w-[50%] bg-[#fbf5f1] h-full items-center flex justify-end pr-[5%] pt-[1.5%]'
					onClick={toggleDrawer}
				>
					<Player
						className='h-[5vh]'
						src={burger}
						autoplay={false}
						loop={false}
						ref={burgerPlayer}
						keepLastFrame
					/>
				</div>
				<div
					style={open ? {top: "10vh"} : {top: "-20vh"}}
					className='bg-[#fbf5f1] flex flex-col z-10 w-screen left-0 absolute transition-all duration-700 ease-in-out'
				>
					{links.map((link) => {
						return (
							<NavLink
								to={`/${link.url}`}
								className={({isActive}) =>
									(isActive
										? "pointer-events-none cursor-default text-[#f9772c] [-webkit-box-reflect:below_-30px_linear-gradient(transparent,_rgba(56,_76,_82,_0.25))] "
										: "text-[#f9972c] hover:text-[#f9772c] hover:[-webkit-box-reflect:below_-30px_linear-gradient(transparent,_rgba(114,_222,_112,_0.3))] ") +
									"relative z-10 flex items-center justify-center gap-[10px] bg-[#fbf5f1] p-[10px] no-underline transition-all"
								}
							>
								<div className='font-navlink-desktop relative ml-[-2.60px] mr-[-2.40px] mt-[-1.10px] w-fit whitespace-nowrap text-[length:var(--navlink-desktop-font-size)] font-[number:var(--navlink-desktop-font-weight)] leading-[var(--navlink-desktop-line-height)] tracking-[var(--navlink-desktop-letter-spacing)] [-webkit-text-stroke:0.1px_#ffffff] [font-style:var(--navlink-desktop-font-style)]'>
									{link.title}
								</div>
							</NavLink>
						);
					})}
				</div>
			</nav>
		</>
	);
};

export default Navbar;
