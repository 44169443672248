import React from "react";
import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import Waitlist from "../pages/Waitlist";
import Test from "../pages/Test";
import About from "../pages/About";
import FAQ from "../pages/FAQ";
import Services from "../pages/Services";

const Router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route path='/' Component={LandingPage} />
			<Route path='/join' Component={Waitlist} />
			<Route path='/about' Component={About} />
			<Route path='/faq' Component={FAQ} />
			<Route path='/services' Component={Services} />
			<Route path='/test' Component={Test} />
		</>,
	),
);
export default Router;
