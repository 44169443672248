import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import WaitlistForm from "../components/WaitlistForm";

const Waitlist = () => {
	const url = `${process.env.REACT_APP_MAILCHIMP_URL}?u=${process.env.REACT_APP_MAILCHIMP_USER}&id=${process.env.REACT_APP_MAILCHIMP_ID}&f_id=${process.env.REACT_APP_MAILCHIMP_F_ID}`;
	return (
		<div className='flex min-h-screen w-full flex-col items-center justify-between bg-[#fbf5f1]'>
			<Navbar />
			<MailchimpSubscribe<any>
				url={url}
				render={(hooks) => {
					return (
						<WaitlistForm
							status={hooks.status}
							subscribe={hooks.subscribe}
						/>
					);
				}}
			/>
			<Footer />
		</div>
	);
};

export default Waitlist;
