import React, {RefObject} from "react";
import {FormInputProps} from "../utilities/Interfaces";
import {
	FormControl,
	FormHelperText,
	InputLabel,
	OutlinedInput,
	ThemeProvider,
} from "@mui/material";
import {mainTheme} from "../utilities/Themes";
import {MuiTelInput} from "mui-tel-input";
import {handleEmailValidity, handleNameValidity} from "../utilities/Validators";

const FormInput = (props: FormInputProps) => {
	return (
		<ThemeProvider theme={mainTheme}>
			{props.type === "tel" ? (
				<MuiTelInput
					required={props.required}
					name={props.name}
					id={props.id}
					value={props.value as string}
					onChange={props.proxy}
					ref={props.ref}
					error={!props.valid}
					variant='outlined'
					defaultCountry='NG'
					label={props.label}
					helperText={props.valid ? "" : "Invalid phone number."}
					preferredCountries={["NG", "GB", "US"]}
					MenuProps={{
						slotProps: {
							paper: {
								style: {backgroundColor: "#ffe8d9"},
								className: "no-scrollbar",
							},
						},
					}}
					sx={{
						"&": {
							width: "100%",
						},
						"& label": {
							paddingLeft: "2vw",
							color: "#f9772c",
						},
						"& .MuiOutlinedInput-notchedOutline": {
							borderColor: "#f9972c",
							color: "#364a50",
							paddingX: "2vw",
							outlineColor: "#f9772c",
							borderRadius: "20svw",
						},
						"& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
							{
								borderColor: "#f9772c",
							},
						"& .MuiOutlinedInput-root.Mui-error": {
							".MuiOutlinedInput-notchedOutline": {
								borderWidth: "2px",
							},
						},
						"& .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline":
							{
								borderColor: "#d32f2f",
							},
					}}
				/>
			) : (
				<FormControl className='w-full'>
					<InputLabel
						required={props.required}
						error={!props.valid}
						sx={{
							"&.Mui-error": {
								color: "#d32f2f",
							},
							"&": {
								color: "#f9772c",
							},
						}}
						className='!pl-[2vw]'
					>
						{props.label}
					</InputLabel>
					<OutlinedInput
						required={props.required}
						name={props.name}
						id={props.id}
						label={props.label}
						type={props.type}
						inputMode={
							props.type as
								| "email"
								| "search"
								| "tel"
								| "text"
								| "url"
								| "none"
								| "numeric"
								| "decimal"
								| undefined
						}
						value={props.value}
						ref={props.ref as RefObject<unknown>}
						error={!props.valid}
						onChange={props.onChange}
						slotProps={
							props.type === "email"
								? {
										input: {
											pattern:
												"^(\\S+)@([A-z]{2,})(\\S*).[a-z]{2,}$",
										},
								  }
								: {input: {minLength: 3}}
						}
						inputProps={{
							onBlur: (e) => {
								if (props.name === "email") {
									handleEmailValidity(e, props.setValid);
								} else {
									handleNameValidity(
										e,
										props.setValid,
										props.placeholder,
									);
								}
							},
							style: {paddingLeft: "2vw", paddingRight: "2vw"},
						}}
						sx={{
							"& .MuiOutlinedInput-notchedOutline": {
								borderColor: "#f9972c",
								color: "#364a50",
								paddingX: "2vw",
								outlineColor: "#f9772c",
							},
							"&:hover .MuiOutlinedInput-notchedOutline": {
								borderColor: "#f9772c",
							},
							"&.Mui-error": {
								".MuiOutlinedInput-notchedOutline": {
									borderWidth: "2px",
								},
							},
							"&.Mui-error:hover .MuiOutlinedInput-notchedOutline":
								{
									borderColor: "#d32f2f",
								},
							borderRadius: "20svw",
						}}
					/>
					<FormHelperText className='!text-[#d32f2f]'></FormHelperText>
				</FormControl>
			)}
		</ThemeProvider>
	);
};

export default FormInput;
