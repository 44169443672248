import {Button, ThemeProvider} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {mainTheme} from "../utilities/Themes";

const Join = ({...props}) => {
	return (
		<Link to='/join' className={props.className}>
			<ThemeProvider theme={mainTheme}>
				<Button
					variant='contained'
					sx={{
						textTransform: "none",
						position: "relative",
						width: "fit-content",
						px: "1.5rem",
						py: "0.75rem",
						borderRadius: "20svw",
						whiteSpace: "nowrap",
						fontSize: "var(--buttontext-desktop-font-size)",
						fontWeight: "var(--buttontext-desktop-font-weight)",
					}}
					className='leading-[var(--buttontext-desktop-line-height)] tracking-[var(--buttontext-desktop-letter-spacing)][font-style:var(--buttontext-desktop-font-style)]'
				>
					Join The Waitlist
				</Button>
			</ThemeProvider>
		</Link>
	);
};

export default Join;
