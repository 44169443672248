import React, {Fragment} from "react";
import logo from "../assets/images/Logo.png";

const Logo = () => {
	return (
		<Fragment>
			<img
				src={logo}
				alt='Logo'
				className='relative h-[5vmax] w-[5vmax] object-cover'
			/>
			<div className="relative w-fit text-center text-[2.5vmax] font-medium leading-[normal] tracking-[0] [font-family:'General_Sans-Medium',Helvetica]">
				<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text transition-all duration-500 text-transparent'>
					IO
				</strong>
			</div>
		</Fragment>
	);
};

export default Logo;
