import React from "react";

function Footer() {
	return (
		<footer className='flex h-[15vh] w-full items-center justify-center bg-[#374d53] px-[10%]'>
			<p className="whitespace-nowrap text-[14px] font-medium leading-[20px] tracking-[0] text-[#ffffff] [font-family:'General_Sans-Medium',Helvetica]">
				© 2023 IO - All Rights Reserved
			</p>
			{/* <div className='inline-flex sm:flex-col items-start gap-[2rem]'>
				<div className="relative mt-[-1.00px] w-fit whitespace-nowrap text-[14px] font-medium leading-[20px] tracking-[0] text-[#ffffff] [font-family:'General_Sans-Medium',Helvetica]">
					Terms of use
				</div>
				<div className="relative mt-[-1.00px] w-fit whitespace-nowrap text-[14px] font-medium leading-[20px] tracking-[0] text-[#ffffff] [font-family:'General_Sans-Medium',Helvetica]">
					Privacy policy
				</div>
			</div> */}
		</footer>
	);
}

export default Footer;
