import React from "react";
import Navbar from "../components/Navbar";

const Services = () => {
	return (
		<div className='flex min-h-screen w-full flex-col items-center justify-between bg-[#fbf5f1]'>
			<Navbar />
			<div className='h-[90vh] from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text text-transparent flex justify-center items-center xs:text-[80px] text-variable-collection-secondary relative m-0 mb-1 text-center text-[length:var(--h1-desktop-font-size)] font-[number:var(--h1-desktop-font-weight)] leading-[var(--h1-desktop-line-height)] tracking-[var(--h1-desktop-letter-spacing)] [font-style:var(--h1-desktop-font-style)] sm:text-[length:var(--h1-mobile-font-size)] sm:font-[number:var(--h1-mobile-font-weight)] sm:leading-[var(--h1-mobile-line-height)] sm:tracking-[var(--h1-mobile-letter-spacing)] sm:[font-style:var(--h1-mobile-font-style)]'>
				Coming Soon...
			</div>
			{/* <div className='hidden'>
				# Our Services At **FinTrack**, we offer a range of services
				designed to simplify your financial life. Here's what you can
				expect when you choose **FinTrack**: ## Bank Account Integration
				Connect all your bank accounts with **FinTrack** and manage them
				from a single platform. No need to switch between different
				banking apps or websites. We support numerous banks and
				financial institutions, providing you with a unified view of
				your finances. ## Real-Time Expense Tracking Track your expenses
				in real-time with **FinTrack**. Every transaction you make,
				regardless of the bank, is recorded and categorized. Understand
				where your money goes and identify potential savings. ##
				Seamless Transactions Perform transactions across all your
				connected bank accounts seamlessly. Whether you're transferring
				money, paying bills, or splitting expenses with friends, do it
				all from **FinTrack**. We ensure all transactions are secure,
				fast, and hassle-free. ## Personalized Financial Insights Get
				personalized insights based on your spending habits and
				financial goals. **FinTrack** analyzes your income, expenses,
				and savings to provide you with tailored recommendations. Make
				informed financial decisions and achieve your financial goals
				with **FinTrack**. ## Secure Platform Your security is our
				priority. **FinTrack** uses advanced security measures,
				including encryption and two-factor authentication, to protect
				your financial information. Rest assured, your data is safe with
				us. ## Customer Support Have a question or facing an issue? Our
				customer support team is here to help. Get quick and reliable
				assistance whenever you need it. Join us at **FinTrack** and
				experience a new way of managing your finances. Welcome aboard!
			</div> */}
		</div>
	);
};

export default Services;
