import React, {useState} from "react";
import Navbar from "../components/Navbar";
import {Player} from "@lottiefiles/react-lottie-player";
import dropdown from "../assets/lottie/dropdown.json";
import {FAQProps} from "../utilities/Interfaces";

const FAQ = () => {
	const data: FAQProps[] = [
		{
			title: "How much should I invest?",
			content:
				"This is really up to you, we would suggest practising on a demo account in the beginning which is like fake money and then move to a real account once you understand the platform and make all the mistakes you will have made on the real account! After you are ready we suggest starting with over 300+ or 500+ This will give you a good amount to profit on each month as a beginner but as a more serious trader you should be looking to trade with 10-100k+ to make enough to cover your full time income, remember making 5-10% a month seems small but on big cash it adds up quickly with compounding, banks give you 5% a year! So master the art of doing 5% a month and you'll be laughing.",
		},
		{
			title: "How much profit do your signals generate?",
			content:
				"Most months we tend to do well over 1000 pips in profit which stands for percent in point. This is a measure of movement in the forex markets, pips for different pairs have different values so its important you use the correct sizing on each signal we give you, we will assist you with the right LOT size upon setup. 1000+ pips monthly will generate at least 5-10% profit monthly minimum with a safe risk size. Forex is a long term game and 95% of traders lose because they want to make quick, fast profit after their first week.",
		},
		{
			title: "How do I execute the trades? Help I am new!",
			content:
				"Not to worry, we will walk you through the entire process. All trades are executed via a broker and a mobile or desktop application called METATRADER which is like a gateway between you and the financial markets/broker. It's easy to use once you learn and its just a matter of executing and placing the trades, occasionally you will need to update the trades TAKE PROFIT OR STOPLOSS. We post constant updates in the VIP channel for you to follow.",
		},
		{
			title: "How do I cancel my current subscription?",
			content:
				"Easy. You can cancel your subscription via our automated Payment robot anytime you wish, if you need any help with that please send a message to the Telegram Bot and we will assist you.",
		},
	];
	const [direction, setDirection] = useState(
		Array.from({length: data.length}, (_, __) => {
			return -1;
		}),
	);
	const paragraph = Array.from({length: data.length}, (_, __) => {
		return React.createRef<HTMLParagraphElement>();
	});
	const lottieRef = Array.from({length: data.length}, (_, __) => {
		return React.createRef<Player>();
	});
	const heights: number[] = [];
	let paragraphs = document.querySelectorAll(".paragraph-2");
	paragraphs.forEach((p) => {
		heights.push(p.clientHeight);
	});
	return (
		<div className='flex min-h-screen w-full flex-col items-center justify-between bg-[#fbf5f1]'>
			<Navbar />
			<div className='h-[90vh] from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text text-transparent flex justify-center items-center xs:text-[80px] text-variable-collection-secondary relative m-0 mb-1 text-center text-[length:var(--h1-desktop-font-size)] font-[number:var(--h1-desktop-font-weight)] leading-[var(--h1-desktop-line-height)] tracking-[var(--h1-desktop-letter-spacing)] [font-style:var(--h1-desktop-font-style)] sm:text-[length:var(--h1-mobile-font-size)] sm:font-[number:var(--h1-mobile-font-weight)] sm:leading-[var(--h1-mobile-line-height)] sm:tracking-[var(--h1-mobile-letter-spacing)] sm:[font-style:var(--h1-mobile-font-style)]'>
				Coming Soon...
			</div>
			<div id='faqs' className='hidden content_wrap_02'>
				<div className='container_flex'>
					<h1 className='h1_c_2'>Need some help?</h1>
					<div className='w-dyn-list'>
						<div role='list' className='w-dyn-items'>
							{data.map((faq, index) => {
								return (
									<div role='listitem' className='w-dyn-item'>
										<div className='faq-question-wrap'>
											<div
												className='faq-question-bar w-inline-block'
												data-open={String(
													direction[index],
												)}
												onClick={() => {
													let newDirection = [
														...direction,
													];
													newDirection[index] =
														newDirection[index] *
														-1;
													setDirection(newDirection);
													lottieRef[
														index
													].current?.setPlayerSpeed(
														3,
													);
													lottieRef[
														index
													].current?.setPlayerDirection(
														direction[index] === 1
															? -1
															: 1,
													);
													lottieRef[
														index
													].current!.play();
												}}
											>
												<div className='question-title'>
													{faq.title}
												</div>
												<Player
													keepLastFrame={true}
													ref={lottieRef[index]}
													src={dropdown}
													style={{
														width: 60,
														height: 60,
													}}
												/>
											</div>
											<div
												className={`faq-content`}
												style={
													direction[index] === 1
														? {
																height: `${heights[index]}px`,
														  }
														: {height: "0px"}
												}
											>
												<p
													ref={paragraph[index]}
													className='paragraph-2'
												>
													{faq.content}
												</p>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQ;
