import React from "react";
// import {ReactComponent as Logo} from "../assets/svg/logo.svg";
import io from "../assets/lottie/io.json";
import expense from "../assets/lottie/expenses.json";
import reasonlot from "../assets/lottie/reasons.json";
import effortless from "../assets/lottie/effortless.json";
import secure from "../assets/lottie/secure.json";
import real_time from "../assets/lottie/real_time.json";
import personal from "../assets/lottie/personal.json";
import mail from "../assets/lottie/mail.json";
import {ReactComponent as FB} from "../assets/svg/ic_baseline-facebook.svg";
import {ReactComponent as IG} from "../assets/svg/ri_instagram-fill.svg";
import {ReactComponent as TW} from "../assets/svg/bi_twitter.svg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Join from "../components/Join";
import {Player} from "@lottiefiles/react-lottie-player";
import Reveal, {Slide} from "react-awesome-reveal";
import {keyframes} from "@emotion/react";
import Logo from "../components/Logo";

const FadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -80px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const LandingPage = () => {
	const reasons = [
		{
			lottie: effortless,
			title: "Effortless",
			body: "Experience a user-friendly interface designed with simplicity in mind. Manage your accounts, track expenses, and perform transactions with ease.",
		},
		{
			lottie: secure,
			title: "Secure",
			body: "Your security is our priority. With robust security measures such as encryption and two-factor authentication, rest assured your financial information is safe with us.",
		},
		{
			lottie: real_time,
			title: "Real-Time",
			body: "Never miss a beat with real-time updates. Stay informed about your transactions and account balances at all times.",
		},
		{
			lottie: personal,
			title: "Personalized",
			body: "Get personalized insights based on your spending habits and financial goals. From budgeting tips to savings recommendations, we’ve got you covered.",
		},
	];
	const ioRef = React.createRef<Player>();
	const expensesRef = React.createRef<Player>();
	const mailRef = React.createRef<Player>();
	const reasonsRefs = Array.from({length: reasons.length + 1}, (_, __) => {
		return React.createRef<Player>();
	});
	return (
		<div className='z-10'>
			<div className='relative flex h-full w-full overflow-x-hidden flex-col justify-center bg-[#fbf5f1]'>
				<div className='absolute top-[-13%] z-0 h-full w-full'>
					<div className='w-[100vw] relative top-0 h-[60vh]'>
						<div className='relative flex flex-col items-center w-[100vw] h-[550vh] overflow-hidden'>
							<div className='top-[528px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[768px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1008px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1248px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1488px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1728px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='top-[1968px] inline-flex items-center'>
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] ml-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
								<div className='mr-[-1.24px] relative w-[242.48px] h-[242.48px] mt-[-1.24px] mb-[-1.24px] rounded-[26.48px] border-[2.48px] border-solid border-[#f0f0f08c]' />
							</div>
							<div className='absolute w-[1425px] top-0 h-[100vh] left-[-352px] -rotate-90 [background:linear-gradient(180deg,rgb(254,242,232)_0%,rgba(246,241,232,0.04)_100%)]' />
							<div className='absolute w-[1425px] h-[100vh] [transform:scale(-1,-1)] right-[-352px] top-[100vh] -rotate-90 [background:linear-gradient(90deg,rgb(254,242,232)_0%,rgba(246,241,232,0.04)_100%)]' />
							<div className='absolute w-[1425px] top-[200vh] h-[100vh] left-[-352px] -rotate-90 [background:linear-gradient(180deg,rgb(254,242,232)_0%,rgba(246,241,232,0.04)_100%)]' />
							<div className='absolute w-[1425px] h-[100vh] [transform:scale(-1,-1)] right-[-352px] top-[300vh] -rotate-90 [background:linear-gradient(90deg,rgb(254,242,232)_0%,rgba(246,241,232,0.04)_100%)]' />
							<div className='absolute w-[1425px] top-[400vh] h-[100vh] left-[-352px] -rotate-90 [background:linear-gradient(180deg,rgb(254,242,232)_0%,rgba(246,241,232,0.04)_100%)]' />
							<div className='absolute w-[1425px] h-[100vh] [transform:scale(-1,-1)] right-[-352px] top-[500vh] -rotate-90 [background:linear-gradient(90deg,rgb(254,242,232)_0%,rgba(246,241,232,0.04)_100%)]' />
							<div className='absolute w-[100vw] h-[300vh] top-0 left-[45px] rounded-[895.45px/763.03px] [background:radial-gradient(50%_50%_at_50%_50%,rgb(254,246,235)_0%,rgba(255,255,255,0)_100%)]' />
						</div>
					</div>
				</div>
				<div className='relative w-full overflow-hidden'>
					<div className='w-full'>
						<Navbar />
						<div className='h-[100vh] sm:h-[90vh] tiny:h-[120vh] xs:justify-evenly flex w-full flex-col justify-center items-center'>
							<div className='relative lg:w-full xl:w-[90%] w-[75%] mb-[1%] inline-flex flex-[0_0_auto] flex-col items-center'>
								<Reveal
									keyframes={FadeInDown}
									triggerOnce
									cascade
								>
									<div className='font-title text-variable-collection-black relative mt-[-1.00px] w-fit text-center text-[length:var(--title-font-size)] font-[number:var(--title-font-weight)] [font-style:var(--title-font-style)] sm:text-[length:var(--title-mobile-font-size)] sm:font-[number:var(--title-mobile-font-weight)] sm:[font-style:var(--title-mobile-font-style)]'>
										Welcome to{" "}
										<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text transition-all duration-500 text-transparent'>
											IO
										</strong>
									</div>
									<p className='font-h1-desktop xs:text-[80px] text-variable-collection-secondary relative m-0 mb-1 text-center text-[length:var(--h1-desktop-font-size)] font-[number:var(--h1-desktop-font-weight)] leading-[var(--h1-desktop-line-height)] tracking-[var(--h1-desktop-letter-spacing)] [font-style:var(--h1-desktop-font-style)] sm:text-[length:var(--h1-mobile-font-size)] sm:font-[number:var(--h1-mobile-font-weight)] sm:leading-[var(--h1-mobile-line-height)] sm:tracking-[var(--h1-mobile-letter-spacing)] sm:[font-style:var(--h1-mobile-font-style)]'>
										<span className='from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent'>
											Manage{" "}
										</span>
										<span className='from-[#f9722c] to-[#f9972c] to-80% bg-gradient-to-b bg-clip-text text-transparent'>
											Your Finances
										</span>
										<span className='from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent'>
											{" "}
											Like Never Before!
										</span>
									</p>
								</Reveal>
							</div>
							<Reveal
								triggerOnce
								delay={500}
								className='scale-[1.5]'
								keyframes={FadeInDown}
							>
								<Join className='scale-[1.5]' />
							</Reveal>
						</div>
					</div>
					<div className='flex w-full flex-col items-center justify-between'>
						<div className='flex sm:tiny:h-[260vh] xs:short:h-[120vh] xs:shorter:h-[140vh] xs:tiny:h-[160vh] sm:shorter:h-[200vh] sm:short:h-[160vh] sm:h-[120vh] items-start w-full sm:mobile:mb-0 mb-[10vh] justify-center'>
							<div className='flex sm:flex-col xs:gap-0 xs:w-full w-[75%] sm:h-full sm:gap-0 h-[80vh] sm:short:justify-normal items-center justify-between gap-[5%]'>
								<Slide
									triggerOnce
									direction='left'
									onVisibilityChange={(inView, entry) => {
										if (inView) {
											setTimeout(() => {
												ioRef.current?.play();
											}, 300);
										}
									}}
								>
									<>
										<Player
											className='relative xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:short:h-[80vw] sm:short:w-[80vw] min-sm:short:h-[37.5vw] min-sm:short:w-[37.5vw] rounded-[20px] bg-cover bg-[50%_50%] shadow-[0px_4px_200px_#ffffff1a]'
											src={io}
											autoplay={false}
											loop={true}
											keepLastFrame={true}
											ref={ioRef}
										/>
									</>
								</Slide>
								<Slide triggerOnce direction='right'>
									<div className='relative inline-flex flex-[0_0_auto] xs:w-[80vw] xs:h-[80vw] w-[60vh] h-[60vh] sm:short:h-[80vw] sm:short:w-[80vw] min-sm:short:h-[37.5vw] min-sm:short:w-[37.5vw] flex-col items-start sm:justify-normal justify-between gap-8'>
										<h1 className='font-h3-desktop sm:w-full relative mt-[-1.00px] text-[length:var(--h3-desktop-font-size)] font-[number:var(--h3-desktop-font-weight)] leading-[var(--h3-desktop-line-height)] tracking-[var(--h3-desktop-letter-spacing)] from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent [font-style:var(--h3-desktop-font-style)]'>
											One App, All Banks
										</h1>
										<p className='font-body1-desktop relative md:min-sm:shorter:text-[20px] xs:text-[20px] sm:text-[30px] text-[length:var(--body1-desktop-font-size)] font-[number:var(--body1-desktop-font-weight)] leading-[var(--body1-desktop-line-height)] tracking-[var(--body1-desktop-letter-spacing)] text-[#000000] [font-style:var(--body1-desktop-font-style)]'>
											With{" "}
											<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text transition-all duration-500 text-transparent'>
												IO
											</strong>{" "}
											, you no longer need to juggle
											between multiple banking apps or
											websites. Our platform allows you to
											connect all your bank accounts,
											regardless of the bank or the type
											of account. Current or savings
											accounts - bring them all under one
											roof. This integration provides a
											consolidated view of your financial
											status, making it easier to manage
											your money.
										</p>
										<Join className='relative sm:min-xs:scale-[1.5] sm:min-xs:left-[5%] sm:shorter:flex sm::shorter:w-full sm:shorter:justify-center' />
									</div>
								</Slide>
							</div>
						</div>
						<div className='flex sm:h-[120vh] sm:tiny:h-[260vh] xs:short:h-[120vh] xs:shorter:h-[140vh] xs:tiny:h-[160vh] sm:shorter:h-[200vh] sm:short:h-[160vh] items-start w-full justify-center sm:mobile:my-0 my-[10vh]'>
							<div className='flex sm:flex-col-reverse xs:gap-0 w-[75%] sm:h-full h-[80vh] items-center sm:short:justify-end justify-between gap-[5%]'>
								<Slide triggerOnce direction='left'>
									<div className='relative flex xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:short:h-[80vw] sm:short:w-[80vw] min-sm:short:h-[37.5vw] min-sm:short:w-[37.5vw] flex-col sm:justify-normal justify-between items-start gap-8'>
										<h1 className='font-h3-desktop sm:w-full relative mr-[-28.00px] mt-[-1.00px] w-full text-[length:var(--h3-desktop-font-size)] font-[number:var(--h3-desktop-font-weight)] leading-[var(--h3-desktop-line-height)] tracking-[var(--h3-desktop-letter-spacing)] from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent [font-style:var(--h3-desktop-font-style)]'>
											Real-Time Expense Tracking
										</h1>
										<p className='font-body1-desktop relative md:min-sm:shorter:text-[20px] xs:text-[20px] sm:text-[30px] text-[length:var(--body1-desktop-font-size)] font-[number:var(--body1-desktop-font-weight)] leading-[var(--body1-desktop-line-height)] tracking-[var(--body1-desktop-letter-spacing)] text-[#000000] [font-style:var(--body1-desktop-font-style)]'>
											<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text transition-all duration-500 text-transparent'>
												IO
											</strong>{" "}
											provides real-time tracking of all
											your expenses. Every coffee you buy,
											every bill you pay, and every penny
											you spend is recorded and
											categorized. This feature allows you
											to see exactly where your money is
											going, helping you identify spending
											habits and areas where you can
											potentially save.
										</p>
										<Join className='relative sm:min-xs:scale-[1.5] sm:min-xs:left-[5%] sm:shorter:flex sm::shorter:w-full sm:shorter:justify-center' />
									</div>
								</Slide>
								<Slide
									triggerOnce
									direction='right'
									onVisibilityChange={(inView, entry) => {
										if (inView) {
											setTimeout(() => {
												expensesRef.current?.play();
											}, 300);
										}
									}}
								>
									<>
										<Player
											className='relative xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:short:h-[80vw] sm:short:w-[80vw] min-sm:short:h-[37.5vw] min-sm:short:w-[37.5vw] rounded-[20px] bg-cover bg-[50%_50%] shadow-[0px_4px_200px_#ffffff1a]'
											src={expense}
											autoplay={false}
											loop={true}
											keepLastFrame={true}
											ref={expensesRef}
										/>
									</>
								</Slide>
							</div>
						</div>
						<div className='flex items-start w-full sm:tiny:h-[280vh] sm:shorter:h-[240vh] sm:short:h-[180vh] xs:short:h-[180vh] xs:shorter:h-[200vh] sm:h-[140vh] xs:h-[180vh] sm:mobile:my-0 justify-center my-[10vh]'>
							<div className='my-[10%] sm:my-0 flex w-full h-full justify-between'>
								<div className='flex sm:flex-col h-[70vh] sm:h-[140vh] xs:h-[180vh] w-full items-center justify-around'>
									<Slide triggerOnce direction='left'>
										<div className='mb-[7.5%] flex flex-col items-start gap-[10%]'>
											<div className='mb-[5%] sm:w-full inline-flex flex-col items-start gap-[15px]'>
												<h1 className='font-h3-desktop relative mr-[-28.00px] mt-[-1.00px] w-full text-[length:var(--h3-desktop-font-size)] font-[number:var(--h3-desktop-font-weight)] leading-[var(--h3-desktop-line-height)] tracking-[var(--h3-desktop-letter-spacing)] from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent [font-style:var(--h3-desktop-font-style)]'>
													Why Choose{" "}
													<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text transition-all duration-500 text-transparent'>
														IO
													</strong>{" "}
													?
												</h1>
											</div>
											<Player
												className='relative xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:short:h-[80vw] sm:short:w-[80vw] min-sm:short:h-[37.5vw] min-sm:short:w-[37.5vw] rounded-[20px] bg-cover bg-[50%_50%] shadow-[0px_4px_200px_#ffffff1a]'
												src={reasonlot}
												autoplay
												loop={true}
												keepLastFrame={true}
												ref={reasonsRefs[0]}
											/>
										</div>
									</Slide>
									<div className='inline-flex xs:w-[80vw] sm:short:w-[80vw] min-sm:short:w-[37.5vw] flex-col items-start w-[60vh] gap-10'>
										{reasons.map((reason, index) => {
											return (
												<Slide
													triggerOnce
													direction='right'
													onVisibilityChange={(
														inView,
														entry,
													) => {
														if (inView) {
															setTimeout(() => {
																reasonsRefs[
																	index
																].current?.play();
															}, 300);
														}
													}}
													delay={index * 100}
												>
													<div className='relative flex w-full flex-[0_0_auto] items-center justify-center gap-8'>
														<div className='flex xs:hidden min-sm:short:h-[7.5vh] min-sm:short:w-[7.5vh] h-[10vh] w-[10vh] items-center justify-center rounded-[20px] bg-[transparent]'>
															<Player
																className='relative flex-[0_0_auto]'
																src={
																	reason.lottie
																}
																autoplay
																loop={true}
																keepLastFrame={
																	true
																}
																ref={
																	reasonsRefs[
																		index +
																			1
																	]
																}
															/>
														</div>
														<div className='relative flex flex-1 grow flex-col items-start gap-[12px]'>
															<h3 className='font-h4-desktop relative mt-[-1.00px] w-fit text-[length:var(--h4-desktop-font-size)] font-[number:var(--h4-desktop-font-weight)] leading-[var(--h4-desktop-line-height)] tracking-[var(--h4-desktop-letter-spacing)] from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent [font-style:var(--h4-desktop-font-style)]'>
																{reason.title}
															</h3>
															<p className='font-body2-desktop sm:text-[21px] relative self-stretch text-[length:var(--body2-desktop-font-size)] font-[number:var(--body2-desktop-font-weight)] leading-[var(--body2-desktop-line-height)] tracking-[var(--body2-desktop-letter-spacing)] text-[#00000099] [font-style:var(--body2-desktop-font-style)]'>
																{reason.body}
															</p>
														</div>
													</div>
												</Slide>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div className='flex items-start w-full sm:tiny:h-[260vh] xs:short:h-[120vh] xs:shorter:h-[140vh] xs:tiny:h-[160vh] sm:shorter:h-[200vh] sm:short:h-[160vh] sm:mobile::my-0 sm:h-[120vh] justify-center my-[10vh]'>
							<div className='flex w-[75%] sm:h-full sm:flex-col-reverse h-[80vh] items-center sm:short:justify-end justify-between gap-[5%]'>
								<Slide triggerOnce direction='left'>
									<div className='relative flex xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:short:h-[80vw] sm:short:w-[80vw] min-sm:short:h-[37.5vw] min-sm:short:w-[37.5vw] flex-col sm:justify-normal justify-between items-start gap-8'>
										<h1 className='font-h3-desktop sm:w-full relative mr-[-28.00px] mt-[-1.00px] w-full text-[length:var(--h3-desktop-font-size)] font-[number:var(--h3-desktop-font-weight)] leading-[var(--h3-desktop-line-height)] tracking-[var(--h3-desktop-letter-spacing)] text-[#364a50] [font-style:var(--h3-desktop-font-style)]'>
											Be The First To Know!!
										</h1>
										<p className='font-body1-desktop md:min-sm:shorter:text-[20px] xs:text-[20px] sm:text-[30px] relative text-[length:var(--body1-desktop-font-size)] font-[number:var(--body1-desktop-font-weight)] leading-[var(--body1-desktop-line-height)] tracking-[var(--body1-desktop-letter-spacing)] text-[#000000] [font-style:var(--body1-desktop-font-style)]'>
											Don’t just track your finances,
											understand them. With personalized
											insights and real-time updates,{" "}
											<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text transition-all duration-500 text-transparent'>
												IO
											</strong>{" "}
											empowers you to make informed
											financial decisions. Sign up for our
											newsletter today and be the first to
											know about new features, financial
											tips, and more!{" "}
											<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text transition-all duration-500 text-transparent'>
												IO
											</strong>{" "}
											- Your finances, your way. Stay
											informed, stay ahead. Join us today!
										</p>
										<Join className='relative sm:min-xs:scale-[1.5] sm:min-xs:left-[5%] sm:shorter:flex sm::shorter:w-full sm:shorter:justify-center' />
									</div>
								</Slide>
								<Slide
									triggerOnce
									direction='right'
									onVisibilityChange={(inView, entry) => {
										if (inView) {
											setTimeout(() => {
												mailRef.current?.play();
											}, 300);
										}
									}}
								>
									<>
										<Player
											className='relative xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:short:h-[80vw] sm:short:w-[80vw] min-sm:short:h-[37.5vw] min-sm:short:w-[37.5vw] rounded-[20px] bg-cover bg-[50%_50%] shadow-[0px_4px_200px_#ffffff1a]'
											src={mail}
											autoplay={false}
											loop={true}
											keepLastFrame={true}
											ref={mailRef}
										/>
									</>
								</Slide>
							</div>
						</div>
					</div>
					<div className='flex sm:hidden h-[40vh] w-full items-start justify-between bg-white px-[10%] pt-[3%]'>
						<div className='flex h-[80%] flex-col items-center justify-between'>
							<div className='inline-flex items-center gap-[15px]'>
								<Logo />
							</div>
							<div className='hidden w-full justify-around'>
								<IG />
								<TW />
								<FB />
							</div>
							<Join />
						</div>
						<div className='inline-flex items-start gap-[75px]'>
							<div className='relative inline-flex flex-[0_0_auto] flex-col items-start gap-[48px]'>
								<div className='font-navlink-desktop relative w-fit whitespace-nowrap text-[length:var(--navlink-desktop-font-size)] font-[number:var(--navlink-desktop-font-weight)] leading-[var(--navlink-desktop-line-height)] tracking-[var(--navlink-desktop-letter-spacing)] text-[#374d53] [font-style:var(--navlink-desktop-font-style)]'>
									Support Us
								</div>
							</div>
							<div className='relative inline-flex flex-[0_0_auto] flex-col items-start gap-[48px]'>
								<div className='font-navlink-desktop relative mt-[-1.00px] w-fit whitespace-nowrap text-[length:var(--navlink-desktop-font-size)] font-[number:var(--navlink-desktop-font-weight)] leading-[var(--navlink-desktop-line-height)] tracking-[var(--navlink-desktop-letter-spacing)] text-[#374d53] [font-style:var(--navlink-desktop-font-style)]'>
									Our Commitment
								</div>
								<div className='font-navlink-desktop relative w-fit whitespace-nowrap text-[length:var(--navlink-desktop-font-size)] font-[number:var(--navlink-desktop-font-weight)] leading-[var(--navlink-desktop-line-height)] tracking-[var(--navlink-desktop-letter-spacing)] text-[#374d53] [font-style:var(--navlink-desktop-font-style)]'>
									Our Team
								</div>
							</div>
							<div className='relative inline-flex flex-[0_0_auto] flex-col items-start gap-[48px]'>
								<div className='font-navlink-desktop relative mt-[-1.00px] w-fit whitespace-nowrap text-[length:var(--navlink-desktop-font-size)] font-[number:var(--navlink-desktop-font-weight)] leading-[var(--navlink-desktop-line-height)] tracking-[var(--navlink-desktop-letter-spacing)] text-[#374d53] [font-style:var(--navlink-desktop-font-style)]'>
									About Us
								</div>
								<div className='font-navlink-desktop relative w-fit whitespace-nowrap text-[length:var(--navlink-desktop-font-size)] font-[number:var(--navlink-desktop-font-weight)] leading-[var(--navlink-desktop-line-height)] tracking-[var(--navlink-desktop-letter-spacing)] text-[#374d53] [font-style:var(--navlink-desktop-font-style)]'>
									FAQs
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</div>
	);
};

export default LandingPage;
