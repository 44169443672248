import {FocusEvent} from "react";

export const capitalize = (text: string) => {
	return text
		.split(/\s+/)
		.map((text) => {
			return text[0].toUpperCase() + text.split(text[0])[1];
		})
		.join(" ");
};

export const handleValidity = (e: FocusEvent<HTMLInputElement>) => {
	const target = e.target as HTMLInputElement;
	if (!target.validity.valid) {
		if (target.parentElement!.getElementsByTagName("span").length > 0) {
			target.parentElement!.removeChild(
				target.parentElement!.getElementsByTagName("span")[0],
			);
		}
		target.parentElement!.insertAdjacentHTML(
			"beforeend",
			`<span class="text-red-600 left-6 text-sm absolute top-full w-full flex justify-start">${target.validationMessage}</span>`,
		);
	}
	if (target.validity.valid) {
		if (target.parentElement!.getElementsByTagName("span").length > 0) {
			target.parentElement!.removeChild(
				target.parentElement!.getElementsByTagName("span")[0],
			);
		}
	}
};

export const handleNameValidity = (
	e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
	validity: React.Dispatch<React.SetStateAction<boolean>>,
	placeholder?: string,
) => {
	const target = e.target as HTMLInputElement;
	if (target.validity.tooShort) {
		target.setCustomValidity(
			`${placeholder} has to be ${target.minLength} characters or more.`,
		);
	} else {
		target.setCustomValidity("");
	}
	target.parentElement!.nextElementSibling!.textContent =
		target.validationMessage;
	validity(!target.validity.tooShort);
};

export const handleEmailValidity = (
	e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
	validity: React.Dispatch<React.SetStateAction<boolean>>,
) => {
	const target = e.target as HTMLInputElement;
	if (target.validity.patternMismatch) {
		target.setCustomValidity(
			'Your email should match the format "example@email.com".',
		);
	} else {
		target.setCustomValidity("");
	}
	target.parentElement!.nextElementSibling!.textContent =
		target.validationMessage;
	validity(!target.validity.patternMismatch);
};

export const handleTelValidity = (
	e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
	validity: React.Dispatch<React.SetStateAction<boolean>>,
) => {
	const target = e.target as HTMLInputElement;
	if (target.validity.patternMismatch) {
		target.setCustomValidity(
			'Your email should match the format "example@email.com".',
		);
	} else {
		target.setCustomValidity("");
	}
	target.parentElement!.nextElementSibling!.textContent =
		target.validationMessage;
	validity(!target.validity.patternMismatch);
};
