import React from "react";
import Navbar from "../components/Navbar";
import {Player} from "@lottiefiles/react-lottie-player";
import mission from "../assets/lottie/mission.json";
import commitment from "../assets/lottie/commitment.json";
import services from "../assets/lottie/services.json";
import join from "../assets/lottie/join.json";
import about from "../assets/lottie/about.json";
import {Fade, Slide} from "react-awesome-reveal";
import Join from "../components/Join";
import Footer from "../components/Footer";

const About = () => {
	const aboutRef = React.createRef<Player>();
	const servicesRef = React.createRef<Player>();
	const commitmentRef = React.createRef<Player>();
	const visionRef = React.createRef<Player>();
	const joinRef = React.createRef<Player>();
	return (
		<div className='flex min-h-screen w-full flex-col items-center justify-between bg-[#fbf5f1]'>
			<Navbar />
			<div className='flex min-h-fit sm:tiny:h-[200vh] xs:short:h-[100vh] xs:shorter:h-[120vh] xs:tiny:h-[140vh] sm:shorter:h-[180vh] sm:short:h-[140vh] sm:flex-col relative xs:gap-0 xs:w-full w-screen box-border group/bg sm:h-full xs:h-[80vw] min-sm:tall:h-[45vw] items-center sm:gap-0 gap-[5%] group/bg px-[5%] sm:items-center sm:justify-normal justify-between my-[5vh] h-[80vh]'>
				<Fade
					triggerOnce={true}
					onVisibilityChange={(inView, entry) => {
						if (inView) {
							setTimeout(() => {
								aboutRef.current?.play();
							}, 300);
						}
					}}
				>
					<Player
						className='relative xs:w-[80vw] xs:h-[80vw] m-0 mb-1 w-[45vw] h-full sm:w-[80vw] min-sm:tall:w-[45vw] min-sm:tall:h-[45vw] rounded-[20px] bg-cover bg-[50%_50%] shadow-[0px_4px_200px_#ffffff1a]'
						src={about}
						autoplay={true}
						loop={true}
						keepLastFrame={true}
						ref={aboutRef}
					/>
				</Fade>
				<div className='relative inline-flex flex-[0_0_auto] xs:w-[80vw] xs:h-[80vw] w-[45vw] h-full sm:w-[80vw] min-sm:tall:w-[45vw] min-sm:tall:h-[45vw] flex-col items-start sm:items-center sm:justify-normal justify-center gap-8'>
					<Fade>
						<h1 className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text sm:text-center transition-all duration-500 text-transparent leading-[100%] text-[7.5vw] sm:text-[15vw] xs:text-[25vw] font-[700] tracking-[var(--h3-desktop-letter-spacing)] [font-style:var(--h3-desktop-font-style)]'>
							About Us
						</h1>
					</Fade>
					<p className='font-body1-desktop from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent relative xs:text-[20px] sm:text-[5vw] text-[2.5vw] font-[number:var(--title-font-weight)] [font-style:var(--title-font-style)]'>
						<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text transition-all duration-500 text-transparent'>
							IO
						</strong>{" "}
						is a personal finance management solution designed to
						provide personalized financial insights. Founded with
						the vision of empowering individuals to achieve
						financial stability, we strive to provide a
						comprehensive platform for managing your financial
						assets.
					</p>
				</div>
			</div>
			{/* <div className='w-[75%] hidden h-[60vh]'>
				// Use MUI steps slider when eventually implementing
				<h1 className='font-h3-desktop sm:w-full text-center relative mt-[-1.00px] text-[length:var(--h3-desktop-font-size)] font-[number:var(--h3-desktop-font-weight)] leading-[var(--h3-desktop-line-height)] tracking-[var(--h3-desktop-letter-spacing)] from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent [font-style:var(--h3-desktop-font-style)]'>
					Our Journey
				</h1>
				<p className='font-body1-desktop text-center relative xs:text-[20px] sm:text-[30px] text-[length:var(--body1-desktop-font-size)] font-[number:var(--body1-desktop-font-weight)] leading-[var(--body1-desktop-line-height)] tracking-[var(--body1-desktop-letter-spacing)] text-[#000000] [font-style:var(--body1-desktop-font-style)]'>
					Our journey began with a simple question - "Why can't
					managing money be easier?" We realized that while there are
					numerous banking apps and financial tools available, none of
					them truly provided a comprehensive solution. That's when{" "}
					<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text duration-500 text-transparent'>
						IO
					</strong>{" "}
					was born. We started with the idea of integrating all bank
					accounts into one platform. We wanted to eliminate the need
					for multiple apps and provide a unified view of all your
					finances. Today,{" "}
					<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text duration-500 text-transparent'>
						IO
					</strong>{" "}
					supports numerous banks and financial institutions, allowing
					users to manage all their accounts seamlessly.
				</p>
			</div> */}
			<div className='flex min-h-fit sm:tiny:h-[200vh] xs:short:h-[100vh] xs:shorter:h-[120vh] xs:tiny:h-[140vh] px-[12.5%] sm:shorter:h-[180vh] sm:short:h-[140vh] sm:h-[100vh] items-start w-full sm:mobile:mb-0 mb-[10vh] justify-center'>
				<div className='flex relative sm:flex-col xs:gap-0 xs:w-full w-screen box-border group/bg sm:h-full sm:gap-0 h-[60vh] sm:short:justify-normal items-center justify-between gap-[5%]'>
					<Slide
						triggerOnce
						direction='left'
						onVisibilityChange={(inView, entry) => {
							if (inView) {
								setTimeout(() => {
									servicesRef.current?.play();
								}, 300);
							}
						}}
					>
						<>
							<Player
								className='relative xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:h-full sm:w-[60vw] min-sm:tall:w-[40vw] min-sm:tall:h-[40vw] rounded-[20px] bg-cover bg-[50%_50%] shadow-[0px_4px_200px_#ffffff1a]'
								src={services}
								autoplay={false}
								loop={true}
								keepLastFrame={true}
								ref={servicesRef}
							/>
						</>
					</Slide>
					<Slide triggerOnce direction='right'>
						<div className='relative inline-flex flex-[0_0_auto] xs:w-[80vw] xs:h-[80vw] w-[60vh] h-[60vh] sm:w-[80vw] min-sm:tall:w-[40vw] min-sm:tall:h-[40vw] flex-col items-start sm:justify-normal justify-center gap-8'>
							<h1 className='font-h3-desktop sm:w-full relative mt-[-1.00px] sm:text-[10vw] sm:text-center text-[length:var(--h3-desktop-font-size)] font-[number:var(--h3-desktop-font-weight)] leading-[var(--h3-desktop-line-height)] tracking-[var(--h3-desktop-letter-spacing)] from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent [font-style:var(--h3-desktop-font-style)]'>
								Our Services
							</h1>
							<p className='font-body1-desktop duration-500 relative xs:text-[20px] sm:text-[30px] text-[length:var(--body1-desktop-font-size)] font-[number:var(--body1-desktop-font-weight)] leading-[var(--body1-desktop-line-height)] tracking-[var(--body1-desktop-letter-spacing)] text-[#000000] [font-style:var(--body1-desktop-font-style)]'>
								<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text duration-500 text-transparent'>
									IO
								</strong>{" "}
								offers a range of features designed to help you
								take control of your finances. From real-time
								expense tracking to seamless transactions, we've
								got you covered. Our intuitive dashboard
								provides a comprehensive overview of your
								financial health, helping you make informed
								decisions.
							</p>
						</div>
					</Slide>
				</div>
			</div>
			<div className='flex min-h-fit sm:tiny:h-[240vh] xs:short:h-[100vh] xs:shorter:h-[120vh] xs:tiny:h-[140vh] px-[12.5%] sm:shorter:h-[180vh] sm:short:h-[140vh] sm:h-[100vh] items-start w-full sm:mobile:mb-0 mb-[10vh] justify-center'>
				<div className='flex relative sm:flex-col-reverse group/bg xs:gap-0 xs:w-full w-screen box-border sm:h-full sm:gap-0 h-[60vh] sm:short:justify-end items-center justify-between gap-[5%]'>
					<Slide triggerOnce direction='left'>
						<div className='relative inline-flex flex-[0_0_auto] xs:w-[80vw] xs:h-[80vw] w-[60vh] h-[60vh] sm:w-[80vw] min-sm:tall:w-[40vw] min-sm:tall:h-[40vw] flex-col items-start sm:justify-normal justify-center gap-8'>
							<h1 className='font-h3-desktop sm:w-full relative mt-[-1.00px] sm:text-[10vw] sm:text-center text-[length:var(--h3-desktop-font-size)] font-[number:var(--h3-desktop-font-weight)] leading-[var(--h3-desktop-line-height)] tracking-[var(--h3-desktop-letter-spacing)] from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent [font-style:var(--h3-desktop-font-style)]'>
								Our Commitment
							</h1>
							<p className='font-body1-desktop duration-500 relative xs:text-[20px] sm:text-[30px] text-[length:var(--body1-desktop-font-size)] font-[number:var(--body1-desktop-font-weight)] leading-[var(--body1-desktop-line-height)] tracking-[var(--body1-desktop-letter-spacing)] text-[#000000] [font-style:var(--body1-desktop-font-style)]'>
								At{" "}
								<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text duration-500 text-transparent'>
									IO
								</strong>
								, we're committed to your financial success. We
								understand that every user is unique, with
								different financial goals and needs. That's why
								we offer personalized insights and
								recommendations, helping you achieve your
								financial goals. With robust security measures
								in place, you can trust us with your financial
								information.
							</p>
						</div>
					</Slide>
					<Slide
						triggerOnce
						direction='right'
						onVisibilityChange={(inView, entry) => {
							if (inView) {
								setTimeout(() => {
									commitmentRef.current?.play();
								}, 300);
							}
						}}
					>
						<>
							<Player
								className='relative xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:h-full sm:w-[60vw] min-sm:tall:w-[40vw] min-sm:tall:h-[40vw] rounded-[20px] bg-cover bg-[50%_50%] shadow-[0px_4px_200px_#ffffff1a]'
								src={commitment}
								autoplay={false}
								loop={true}
								keepLastFrame={true}
								ref={commitmentRef}
							/>
						</>
					</Slide>
				</div>
			</div>
			<div className='flex min-h-fit sm:tiny:h-[200vh] xs:short:h-[80vh] xs:shorter:h-[100vh] xs:tiny:h-[120vh] px-[12.5%] sm:shorter:h-[160vh] sm:short:h-[120vh] sm:h-[100vh] items-start w-full sm:mobile:mb-0 mb-[10vh] justify-center'>
				<div className='flex relative sm:flex-col group/bg xs:gap-0 xs:w-full w-screen box-border sm:h-full sm:gap-0 h-[60vh] sm:short:justify-normal items-center justify-between gap-[5%]'>
					<Slide
						triggerOnce
						direction='left'
						onVisibilityChange={(inView, entry) => {
							if (inView) {
								setTimeout(() => {
									visionRef.current?.play();
								}, 300);
							}
						}}
					>
						<>
							<Player
								className='relative xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:h-full sm:w-[60vw] min-sm:tall:w-[40vw] min-sm:tall:h-[40vw] rounded-[20px] bg-cover bg-[50%_50%] shadow-[0px_4px_200px_#ffffff1a]'
								src={mission}
								autoplay={false}
								loop={true}
								keepLastFrame={true}
								ref={visionRef}
							/>
						</>
					</Slide>
					<Slide triggerOnce direction='right'>
						<div className='relative inline-flex flex-[0_0_auto] xs:w-[80vw] xs:h-[80vw] w-[60vh] h-[60vh] sm:w-[80vw] min-sm:tall:w-[40vw] min-sm:tall:h-[40vw] flex-col items-start sm:justify-normal justify-center gap-8'>
							<h1 className='font-h3-desktop sm:w-full relative mt-[-1.00px] sm:text-[10vw] sm:text-center text-[length:var(--h3-desktop-font-size)] font-[number:var(--h3-desktop-font-weight)] leading-[var(--h3-desktop-line-height)] tracking-[var(--h3-desktop-letter-spacing)] from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent [font-style:var(--h3-desktop-font-style)]'>
								Our Vision
							</h1>
							<p className='font-body1-desktop duration-500 relative xs:text-[20px] sm:text-[30px] text-[length:var(--body1-desktop-font-size)] font-[number:var(--body1-desktop-font-weight)] leading-[var(--body1-desktop-line-height)] tracking-[var(--body1-desktop-letter-spacing)] text-[#000000] [font-style:var(--body1-desktop-font-style)]'>
								Our vision is to empower individuals around the
								world to achieve financial freedom. We believe
								in a future where everyone, regardless of their
								financial background, can make informed
								financial decisions with confidence and ease.
							</p>
						</div>
					</Slide>
				</div>
			</div>
			<div className='flex min-h-fit sm:tiny:h-[200vh] xs:short:h-[80vh] xs:shorter:h-[100vh] xs:tiny:h-[120vh] px-[12.5%] sm:shorter:h-[160vh] sm:short:h-[120vh] sm:h-[100vh] items-start w-full sm:mobile:mb-0 mb-[10vh] justify-center'>
				<div className='flex relative sm:flex-col-reverse group/bg xs:gap-0 xs:w-full w-screen box-border sm:h-full sm:gap-0 h-[60vh] sm:short:justify-end items-center justify-between gap-[5%]'>
					<Slide triggerOnce direction='left'>
						<div className='relative inline-flex flex-[0_0_auto] xs:w-[80vw] xs:h-[80vw] w-[60vh] h-[60vh] sm:w-[80vw] min-sm:tall:w-[40vw] min-sm:tall:h-[40vw] flex-col items-start sm:justify-normal justify-center gap-8'>
							<h1 className='font-h3-desktop sm:w-full relative mt-[-1.00px] sm:text-[10vw] sm:text-center text-[length:var(--h3-desktop-font-size)] font-[number:var(--h3-desktop-font-weight)] leading-[var(--h3-desktop-line-height)] tracking-[var(--h3-desktop-letter-spacing)] from-[#384c52] to-[#4f6c75] to-80% bg-gradient-to-b bg-clip-text text-transparent [font-style:var(--h3-desktop-font-style)]'>
								Join Us
							</h1>
							<p className='font-body1-desktop duration-500 relative xs:text-[20px] sm:text-[30px] text-[length:var(--body1-desktop-font-size)] font-[number:var(--body1-desktop-font-weight)] leading-[var(--body1-desktop-line-height)] tracking-[var(--body1-desktop-letter-spacing)] text-[#000000] [font-style:var(--body1-desktop-font-style)]'>
								Join us on this journey towards financial
								freedom. Join the{" "}
								<strong className='from-[#f9722c] to-[#f9972c] to-60% bg-gradient-to-r bg-clip-text text-transparent'>
									IO
								</strong>{" "}
								waitlist today and take the first step towards a
								stress-free financial life. Welcome aboard!
							</p>
							<Join />
						</div>
					</Slide>
					<Slide
						triggerOnce
						direction='right'
						onVisibilityChange={(inView, entry) => {
							if (inView) {
								setTimeout(() => {
									joinRef.current?.play();
								}, 300);
							}
						}}
					>
						<>
							<Player
								className='relative xs:w-[80vw] xs:h-[80vw] h-[60vh] w-[60vh] sm:h-full sm:w-[60vw] min-sm:tall:w-[40vw] min-sm:tall:h-[40vw] rounded-[20px] bg-cover bg-[50%_50%] shadow-[0px_4px_200px_#ffffff1a]'
								src={join}
								autoplay={false}
								loop={true}
								keepLastFrame={true}
								ref={joinRef}
							/>
						</>
					</Slide>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default About;
