import React, {ChangeEvent, FormEventHandler, useRef, useState} from "react";
import FormInput from "./FormInput";
import {Button, ThemeProvider} from "@mui/material";
import {matchIsValidTel} from "mui-tel-input";
import {Form} from "react-router-dom";
import {mainTheme} from "../utilities/Themes";

const WaitlistForm = (props: {
	status: "error" | "success" | "sending" | null;
	subscribe: (data: any) => void;
}) => {
	const inputCont = useRef<HTMLDivElement>(null);
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [tel, setTel] = useState("");
	const [email, setEmail] = useState("");
	const [firstNameValid, setFirstNameValid] = useState(true);
	const [middleNameValid, setMiddleNameValid] = useState(true);
	const [lastNameValid, setLastNameValid] = useState(true);
	const [telValid, setTelValid] = useState(true);
	const [emailValid, setEmailValid] = useState(true);

	const handleFirstName = (e: ChangeEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		setFirstName(target.value);
	};

	const handleMiddleName = (e: ChangeEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		setMiddleName(target.value);
	};

	const handleLastName = (e: ChangeEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		setLastName(target.value);
	};

	const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		setEmail(target.value);
	};

	const handleTel = (tel: string) => {
		setTel(() => {
			setTelValid(matchIsValidTel(tel));
			return tel;
		});
	};

	const handleSubmit: FormEventHandler<HTMLFormElement> = async (
		e: React.FormEvent<HTMLFormElement>,
	) => {
		e.preventDefault();
		let data = {
			EMAIL: email,
			FNAME: firstName,
			MNAME: middleName,
			LNAME: lastName,
			MMERGE3: tel,
		};
		props.subscribe(data);
	};
	return (
		<Form
			action='https://app.us17.list-manage.com/subscribe/post'
			method='POST'
			onSubmit={handleSubmit}
			className='flex short:h-[70vh] shorter:h-[80vh] tiny:h-[100vh] h-[60vh] my-[5vh] lg:w-[50vw] xs:w-[80vw] w-[30vw] flex-col items-start justify-around'
		>
			<input type='hidden' name='u' value='162e7268421f46ebadccf1377' />
			<input type='hidden' name='id' value='5252cd0f66' />
			<h2 className='text-4xl font-bold text-[#384C52]'>
				Join the Waitlist
			</h2>
			<div ref={inputCont} className='flex w-full flex-col gap-y-10'>
				<FormInput
					required={true}
					valid={firstNameValid}
					setValid={setFirstNameValid}
					name='FNAME'
					type='text'
					label='First Name'
					placeholder='First Name'
					value={firstName}
					minLength={6}
					onChange={handleFirstName}
				/>
				<FormInput
					required={false}
					valid={middleNameValid}
					setValid={setMiddleNameValid}
					name='MNAME'
					type='text'
					label='Middle Name'
					placeholder='Middle Name'
					value={middleName}
					minLength={6}
					onChange={handleMiddleName}
				/>
				<FormInput
					required={true}
					valid={lastNameValid}
					setValid={setLastNameValid}
					name='LNAME'
					type='text'
					label='Last Name'
					placeholder='Last Name'
					value={lastName}
					minLength={6}
					onChange={handleLastName}
				/>
				<FormInput
					required={true}
					valid={telValid}
					setValid={setTelValid}
					id='phoneInput'
					name='MMERGE3'
					label='Phone Number'
					type='tel'
					value={tel}
					placeholder='Phone Number'
					proxy={handleTel}
				/>
				<FormInput
					required={true}
					valid={emailValid}
					setValid={setEmailValid}
					name='EMAIL'
					label='Email'
					type='email'
					placeholder='Email'
					value={email}
					pattern='^(\S+)@([A-z]{2,})(\S*)\.[a-z]{2,}$'
					onChange={handleEmail}
				/>
			</div>
			<ThemeProvider theme={mainTheme}>
				<Button
					type='submit'
					variant='contained'
					sx={{
						textTransform: "none",
						position: "relative",
						width: "fit-content",
						px: "1.5rem",
						py: "0.75rem",
						borderRadius: "20svw",
						whiteSpace: "nowrap",
						fontSize: "var(--buttontext-desktop-font-size)",
						fontWeight: "var(--buttontext-desktop-font-weight)",
					}}
					className='leading-[var(--buttontext-desktop-line-height)] tracking-[var(--buttontext-desktop-letter-spacing)][font-style:var(--buttontext-desktop-font-style)]'
				>
					Submit
				</Button>
			</ThemeProvider>
		</Form>
	);
};

export default WaitlistForm;
