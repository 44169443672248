import {createTheme} from "@mui/material/styles";

export const mainTheme = createTheme({
	palette: {
		primary: {
			main: "#f9972c",
			light: "#fbf5f1",
			dark: "#f9772c",
			contrastText: "#000000",
		},
		info: {
			main: "#f9972c",
			light: "#fbf5f1",
			dark: "#f9772c",
			contrastText: "#364a50",
		},
	},
});
